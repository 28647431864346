.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  opacity: 1 !important;
  transition: opacity 1s ease-in-out;
}

.opacity-0 {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

/* Example: Ensure no excessive padding/margin */
.section {
    padding: 16px; /* Adjust as necessary */
    margin: 0 auto; /* Center content */
    max-width: 1200px; /* Ensure content is not too wide */
}

/* Example: Check overflow properties */
.container {
    overflow: hidden; /* Adjust if necessary */
}

/* Add media query for mobile responsiveness */
@media (max-width: 640px) {
  .pricing-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    width: 100%;
    margin-bottom: 16px; /* Add space between stacked cards */
  }
}

/* Ensure grid layout for larger screens */
@media (min-width: 641px) {
  .pricing-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns for larger screens */
    gap: 16px;
  }
}

.pricing-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.pricing-card:hover {
  transform: scale(1.08) translateY(-5px); /* Enlarge and lift the card */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15); /* Deeper shadow effect */
  background-color: #f0f4f8; /* Slight background color change */
}

.journey-button {
  padding: 8px 16px; /* Smaller padding */
  font-size: 14px; /* Smaller font size */
  border-radius: 9999px; /* Keep the rounded style */
  transition: background-color 0.3s ease;
}

.journey-button:hover {
  background-color: #6dbb8b; /* Hover effect */
}

/* Add these new styles at the end of the file */
.toggle-checkbox {
  right: 0;
  border-color: #68D391;
  transition: all 0.3s;
}

.toggle-checkbox:checked {
  right: 6px;
  border-color: #68D391;
}

.toggle-label {
  width: 48px;
  height: 24px;
  background-color: #ddd;
  transition: all 0.3s;
}

.toggle-checkbox:checked + .toggle-label {
  background-color: #68D391;
}

.feature-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: scale(1.12); /* Increased scale for a more pronounced effect */
  box-shadow: 
    0 12px 40px rgba(0, 0, 0, 0.3), /* Deeper shadow for more depth */
    0 0 25px rgba(133, 208, 163, 0.8); /* Stronger green glow using the secondary color */
}

/* Add these new styles at the end of the file */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Baloo+2:wght@600&display=swap');

.section-header {
  font-family: 'Pacifico', cursive;
  font-size: 2.5rem; /* Slightly reduced size to fit the new font */
  background: linear-gradient(to right, #6ca0dc, #5a8ac2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-align: center;
  padding: 10px;
  text-shadow: 2px 2px 4px rgba(108, 160, 220, 0.3);
  transition: transform 0.3s ease, text-shadow 0.3s ease;
}

.section-header:hover {
  transform: scale(1.05);
  text-shadow: 3px 3px 6px rgba(108, 160, 220, 0.4);
}
