/* navbar.css */

/* Navbar container */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 50;
    padding: 0 1rem;
  }
  
  /* Navbar content */
  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    max-width: 1120px;
    margin: 0 auto;
  }
  
  /* Navbar links (desktop) */
  .navbar-links {
    display: none;
  }
  
  .navbar-links a {
    color: #4d4d4d;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.5rem;
    margin-right: 1rem;
  }
  
  .navbar-links a:hover {
    color: #6ca0dc;
  }
  
  /* Mobile menu button */
  .mobile-menu-button {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: none;
    background: none;
    cursor: pointer;
    color: #6ca0dc;
  }
  
  /* Mobile menu */
  .mobile-menu {
    display: none;
    flex-direction: column;
    background-color: #ffffff;
    border-top: 1px solid #e5e7eb;
    padding: 1rem;
  }
  
  .mobile-menu a {
    color: #4d4d4d;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 0;
  }
  
  .mobile-menu a:hover {
    color: #6ca0dc;
  }
  
  /* Show mobile menu when active */
  .mobile-menu.active {
    display: flex;
  }
  
  /* Media query to show links on larger screens */
  @media (min-width: 640px) {
    .navbar-links {
      display: flex;
    }
    .mobile-menu-button {
      display: none;
    }
    .mobile-menu {
      display: none;
    }
  }
  